@charset "UTF-8";
/* bootstrap 3 styles to maintain until markup is updated to BS5   */
.btn.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
}

.visible-xs {
  display: none;
}

/* ui.bootstrap fixes */
[uib-dropdown].dropdown.open .dropdown-menu {
  display: block;
}

[uib-modal-window] .modal-header {
  display: block;
}

.uib-weeks td.h6 {
  font-size: smaller;
  color: #828282;
}

.uib-day button.btn.btn-default {
  border: 1px solid #e3e3e3;
}

[uib-tabset] .nav-tabs > li.active > a {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.form-inline input.form-control {
  width: auto;
  display: inline-block;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #337ab7;
}

.nav-pills-tight > li > a {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.nav-pills > li > a {
  border-radius: 4px;
}

.nav > li > a:focus, .nav > li > a:hover {
  text-decoration: none;
  background-color: #eee;
}

[uib-pagination].pagination {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
}
[uib-pagination].pagination > .active > a, [uib-pagination].pagination > .active > a:focus, [uib-pagination].pagination > .active > a:hover, [uib-pagination].pagination > .active > span, [uib-pagination].pagination > .active > span:focus, [uib-pagination].pagination > .active > span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
[uib-pagination].pagination > li > a:focus, [uib-pagination].pagination > li > a:hover, [uib-pagination].pagination > li > span:focus, [uib-pagination].pagination > li > span:hover {
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

[uib-pagination].pagination > li > a, [uib-pagination].pagination > li > span {
  padding: 5px 10px;
}

[uib-pagination].pagination-sm {
  font-size: 12px;
}

[uib-popover-template-popup].fade.in, [uib-popover-html-popup].fade.in {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}
[uib-popover-template-popup].fade.in .popover-content, [uib-popover-html-popup].fade.in .popover-content {
  padding: 9px 14px;
}
[uib-popover-template-popup].fade.in .popover-title, [uib-popover-html-popup].fade.in .popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
[uib-popover-template-popup].fade.in h4, [uib-popover-template-popup].fade.in h5, [uib-popover-html-popup].fade.in h4, [uib-popover-html-popup].fade.in h5 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}
[uib-popover-template-popup].fade.in > .arrow, [uib-popover-html-popup].fade.in > .arrow {
  border-width: 11px;
}
[uib-popover-template-popup].fade.in > .arrow, [uib-popover-template-popup].fade.in > .arrow:after, [uib-popover-html-popup].fade.in > .arrow, [uib-popover-html-popup].fade.in > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

[uib-popover-template], [uib-popover-html] {
  cursor: zoom-in;
}

[uib-popover-template-popup].popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
[uib-popover-template-popup].popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff;
}

[uib-popover-template-popup].popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}

[uib-tooltip-popup].tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  filter: alpha(opacity=0);
  opacity: 0;
}
[uib-tooltip-popup].tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
[uib-tooltip-popup].tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
[uib-tooltip-popup].tooltip.in {
  opacity: 0.8;
}
[uib-tooltip-popup].tooltip .tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  border-radius: 4px;
}
[uib-tooltip-popup].tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

/* legacy uib elements using glyphicons, e.g. datepicker */
i.glyphicon.glyphicon-chevron-right:before {
  content: "→";
}
i.glyphicon.glyphicon-chevron-left:before {
  content: "←";
}

ul.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
}

#resident-search ul.nav.nav-pills.nav-justified {
  min-width: 70%;
}

.modal-content .close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  border: none;
  opacity: 0.2;
}

.modal-backdrop.in {
  opacity: 0.5;
}

li.uib-tab.hide {
  display: none;
}

.in.collapse {
  display: block !important;
}

.bvw-carousel .glyphicon.glyphicon-chevron-left:before {
  content: "‹ ";
  font-size: 2em;
  color: #2280aa;
}

.bvw-carousel .glyphicon.glyphicon-chevron-right:after {
  content: " ›";
  font-size: 2em;
  color: #2280aa;
}

/* Carousel */
.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img {
  line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
  }
  .carousel-inner > .item.active.right,
  .carousel-inner > .item.next {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  .carousel-inner > .item.active.left,
  .carousel-inner > .item.prev {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  .carousel-inner > .item.active,
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#80000000", endColorstr="#00000000", GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#80000000", GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control:focus,
.carousel-control:hover {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: 0.9;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-next,
.carousel-control .icon-prev {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: "‹";
}

.carousel-control .icon-next:before {
  content: "›";
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9 ;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next,
  .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    font-size: 30px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -15px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -15px;
  }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}